import { Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import Utils from '../../../utils';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appImageFallback]',
})
export class ImageFallbackDirective {
  @Input() fallback: string;
  @Input() normalStyle: { [klass: string]: any };
  @Input() fallbackStyle: { [klass: string]: any };

  location: Location;

  @HostListener('error') onError() {
    // TODO: Possible infinite loop?
    if (Utils.holdsValue(this.fallback)) {
      for (const styleKey in this.fallbackStyle) {
        if (!this.fallbackStyle.hasOwnProperty(styleKey)) {
          continue;
        }
        this.renderer.setStyle(this.el.nativeElement, styleKey, this.fallbackStyle[styleKey]);
      }
      this.renderer.setProperty(this.el.nativeElement, 'src', this.fallback);
    }
  }

  @HostListener('load', ['$event']) onLoadedData(event: Event) {
    if (document && document.location) {
      const target = event.currentTarget as HTMLImageElement;
      const fallbackAbsoluteUrl = new URL(this.fallback, document.location.toString()).href;
      if (
        target &&
        Utils.holdsValue(target.src) &&
        Utils.holdsValue(fallbackAbsoluteUrl) &&
        target.src === fallbackAbsoluteUrl
      ) {
        return;
      }
    } else {
      console.warn('Missing document element.');
    }

    for (const styleKey in this.normalStyle) {
      if (!this.normalStyle.hasOwnProperty(styleKey)) {
        continue;
      }
      this.renderer.setStyle(this.el.nativeElement, styleKey, this.normalStyle[styleKey]);
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.location = location;
  }
}
