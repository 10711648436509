import { Injectable } from '@angular/core';
import Utils from '../../utils';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CSGOGSI, STEAM_API } from 'csgo-gsi-advanced-types';
import RoundWinningType = CSGOGSI.constants.RoundWinningType;
import WeaponNames = CSGOGSI.constants.WeaponNames;
import MatchEventTypes = CSGOGSI.constants.MatchEventTypes;
import Weapon = CSGOGSI.types.Weapon;
import ClanDetailsForPlayersResponse = STEAM_API.ClanDetailsForPlayersResponse;

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private readonly imgPath = 'assets/images';

  private readonly mapIconPath = this.imgPath + '/maps/icons';
  private readonly mapIconPrefix = 'map_icon_';
  private readonly mapIconExtension = '.svg';
  private readonly mapIconDefault = this.mapIconPath + '/competitive.svg';

  private readonly mapImagePath = this.imgPath + '/maps/screenshots';
  private readonly mapImageExtension = '.png';
  private readonly mapImageDefault = this.mapImagePath + '/vs_background_00.png';

  private readonly teamIconPath = this.imgPath + '/teams';
  private readonly teamIconSuffix = '_logo';
  private readonly teamIconExtension = '.svg';

  private readonly roundWinsIconPath = this.imgPath + '/round-wins';
  private readonly roundWinsIconExtension = '.svg';

  private readonly hudIconPath = this.imgPath + '/hud';

  private readonly throwableIconPath = this.hudIconPath + '/throwables';
  private readonly throwableIconExtension = '.svg';

  private readonly equipmentPath = this.imgPath + '/equipment';
  private readonly weaponImagePath = this.equipmentPath + '/weapons';

  private readonly weaponImage3dPath = this.weaponImagePath + '/3d';
  private readonly weaponImageDefault3dPath = this.weaponImage3dPath + '/default';
  private readonly weaponImageDefault3dSuffix = '_light_large';
  private readonly throwableImage3dPath = this.weaponImage3dPath + '/throwables';

  private readonly weaponImageExtension = '.png';
  private readonly throwableImageExtension = '.png';

  private readonly soundsPath = 'assets/sounds';

  constructor(private http: HttpClient) {}

  public getMapIcon(mapName?: string): string {
    let icon = this.mapIconDefault;
    if (Utils.holdsValue(mapName)) {
      icon = `${this.mapIconPath}/${this.mapIconPrefix}${mapName}${this.mapIconExtension}`;
    }
    return icon;
  }

  public getMapImage(mapName?: string): string {
    let image = this.mapImageDefault;
    if (Utils.holdsValue(mapName)) {
      image = `${this.mapImagePath}/${mapName}${this.mapImageExtension}`;
    }
    return image;
  }

  public getTeamIcon(teamName: string): string {
    if (!teamName) {
      return '';
    }
    return `${this.teamIconPath}/${teamName.toLowerCase()}${this.teamIconSuffix}${
      this.teamIconExtension
    }`;
  }

  public getRoundWinIcon(roundWin: RoundWinningType): string {
    let icon = '';
    const roundWinString = roundWin.toString();
    const matches = roundWinString.match(/.+_win_(.+)/);

    if (matches && matches.length === 2) {
      icon = `${this.roundWinsIconPath}/${matches[1]}${this.roundWinsIconExtension}`;
    }
    return icon;
  }

  public getTrophyIcon(): string {
    return `${this.roundWinsIconPath}/trophy.svg`;
  }

  public getTieIcon(): string {
    return `${this.roundWinsIconPath}/tie.svg`;
  }

  public getNormalKillIcon(): string {
    return `${this.hudIconPath}/kill_icon.svg`;
  }

  public getHeadshotKillIcon(): string {
    return `${this.hudIconPath}/kill_icon_headshot.svg`;
  }

  public getThrowableIcon(throwable: WeaponNames): string {
    return `${this.throwableIconPath}/${throwable}${this.throwableIconExtension}`;
  }

  public getWeaponImage3dDefault(weapon: Weapon): string {
    return `${this.weaponImageDefault3dPath}/${weapon.name}${this.weaponImageExtension}`;
  }

  public getWeaponImage3d(weapon: Weapon): string {
    const name = weapon.name;
    const paintkit = weapon.paintkit;
    if (paintkit === 'default') {
      return this.getWeaponImage3dDefault(weapon);
    } else {
      return `${this.weaponImage3dPath}/${name}_${paintkit}${this.weaponImageDefault3dSuffix}${this.weaponImageExtension}`;
    }
  }

  public getThrowableImage3d(weapon: Weapon): string {
    return `${this.throwableImage3dPath}/${weapon.name}${this.throwableImageExtension}`;
  }

  public getSound(event: MatchEventTypes): string {
    let sound = '';
    switch (event) {
      case MatchEventTypes.BOMB_DROPPED:
        sound = `${this.soundsPath}/bombdropped.mp3`;
        break;
      case MatchEventTypes.BOMB_PICKED:
        break;
      case MatchEventTypes.BOMB_PLANTED:
        sound = `${this.soundsPath}/alaaarm.mp3`;
        break;
      case MatchEventTypes.BOMB_EXPLODED:
        sound = `${this.soundsPath}/yippieyayeah.mp3`;
        break;
      case MatchEventTypes.BOMB_DEFUSED:
        sound = `${this.soundsPath}/WOLOLO.mp3`;
        break;
      case MatchEventTypes.FIRST_BLOOD:
        sound = `${this.soundsPath}/firstblood.wav`;
        break;
      case MatchEventTypes.DOUBLE_KILL:
        sound = `${this.soundsPath}/doublekill.wav`;
        break;
      case MatchEventTypes.TRIPLE_KILL:
        sound = `${this.soundsPath}/triplekill.wav`;
        break;
      case MatchEventTypes.ULTRA_KILL:
        sound = `${this.soundsPath}/ultrakill.wav`;
        break;
      case MatchEventTypes.RAMPAGE:
        sound = `${this.soundsPath}/rampage.wav`;
        break;
      case MatchEventTypes.KILLING_SPREE:
        sound = `${this.soundsPath}/killingspree.wav`;
        break;
      case MatchEventTypes.DOMINATING:
        sound = `${this.soundsPath}/dominating.wav`;
        break;
      case MatchEventTypes.MEGA_KILL:
        sound = `${this.soundsPath}/megakill.wav`;
        break;
      case MatchEventTypes.UNSTOPPABLE:
        sound = `${this.soundsPath}/unstoppable.wav`;
        break;
      case MatchEventTypes.WICKED_SICK:
        sound = `${this.soundsPath}/wickedsick.wav`;
        break;
      case MatchEventTypes.MONSTER_KILL:
        sound = `${this.soundsPath}/monsterkill.wav`;
        break;
      case MatchEventTypes.GODLIKE:
        sound = `${this.soundsPath}/godlike.wav`;
        break;
      case MatchEventTypes.BEYOND_GODLIKE:
        sound = `${this.soundsPath}/holyshit.wav`;
        break;
      case MatchEventTypes.TEAM_KILL:
        sound = `${this.soundsPath}/blindoderbloed.mp3`;
        break;
      case MatchEventTypes.ACE:
        sound = `${this.soundsPath}/ownage.wav`;
        break;
      case MatchEventTypes.ROUND_NEW:
        break;
      case MatchEventTypes.ROUND_WON:
        break;
      case MatchEventTypes.ROUND_LOST:
        break;
      case MatchEventTypes.ROUND_LIVE:
        break;
      case MatchEventTypes.ROUND_OVER:
        break;
      case MatchEventTypes.ROUND_FLAWLESS:
        sound = `${this.soundsPath}/flawlessvictory.mp3`;
        break;
      case MatchEventTypes.ROUND_HUMILIATION:
        sound = `${this.soundsPath}/humiliation.wav`;
        break;
      case MatchEventTypes.MAP_LIVE:
        break;
      case MatchEventTypes.MATCH_NEW:
        sound = `${this.soundsPath}/readytorumblelong.mp3`;
        break;
      case MatchEventTypes.MATCH_WON:
        sound = `${this.soundsPath}/matchwon.wav`;
        break;
      case MatchEventTypes.MATCH_LOST:
        sound = `${this.soundsPath}/matchlost.wav`;
        break;
      case MatchEventTypes.MATCH_TIE:
        sound = `${this.soundsPath}/mutualdestruction.mp3`;
        break;
      case MatchEventTypes.GAME_OVER:
        break;
      case MatchEventTypes.CLUTCH_START:
        sound = `${this.soundsPath}/oneandonly.wav`;
        break;
      case MatchEventTypes.CLUTCH_4:
        sound = `${this.soundsPath}/4.wav`;
        break;
      case MatchEventTypes.CLUTCH_3:
        sound = `${this.soundsPath}/3.wav`;
        break;
      case MatchEventTypes.CLUTCH_2:
        sound = `${this.soundsPath}/2.wav`;
        break;
      case MatchEventTypes.CLUTCH_1:
        sound = `${this.soundsPath}/1.wav`;
        break;
      case MatchEventTypes.CLUTCH_WON_SIMPLE:
        sound = `${this.soundsPath}/excellent.mp3`;
        break;
      case MatchEventTypes.CLUTCH_WON_DIFFICULT:
        sound = `${this.soundsPath}/hilfeundsupport.mp3`;
        break;
      case MatchEventTypes.CLUTCH_LOST_SIMPLE:
        sound = `${this.soundsPath}/waskannstdu.wav`;
        break;
      case MatchEventTypes.CLUTCH_LOST_DIFFICULT:
        sound = `${this.soundsPath}/failhorn.mp3`;
        break;
      case MatchEventTypes.ONE_ENEMY_LEFT:
        sound = `${this.soundsPath}/finishhim.mp3`;
        break;
      case MatchEventTypes.LAST_MAN_STANDING:
        break;
      case MatchEventTypes.PUMPGUN:
        sound = `${this.soundsPath}/pumpgun.mp3`;
        break;
      case MatchEventTypes.GLASS_CANNON:
        sound = `${this.soundsPath}/glasscannon.mp3`;
        break;
      case MatchEventTypes.JAMES_BOND:
        sound = `${this.soundsPath}/bond.mp3`;
        break;
      case MatchEventTypes.BE_WATER:
        sound = `${this.soundsPath}/water.mp3`;
        break;
      case MatchEventTypes.HALT_STOP:
        sound = `${this.soundsPath}/haltstop.mp3`;
        break;
      case MatchEventTypes.UNTERBRECHEN:
        sound = `${this.soundsPath}/unterbrechen.mp3`;
        break;
      case MatchEventTypes.JETZT_GEHTS_LOS:
        sound = `${this.soundsPath}/jetztgehtslos.mp3`;
        break;
    }
    return sound;
  }

  // Steam Web API

  public getPlayerAvatar(steamid: string): Promise<string> {
    const url = `${environment.steamApi.baseUrl}${environment.steamApi.playerAvatar}/${steamid}`;

    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'text' }).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(`Could not get avatar for player ${steamid}. Error ${error}`);
        },
      );
    });
  }

  public getClanDetails(steamIds: string[]): Promise<ClanDetailsForPlayersResponse> {
    let url = `${environment.steamApi.baseUrl}${environment.steamApi.clanDetailsForPlayers}/`;
    for (let i = 0; i < steamIds.length; ++i) {
      if (i === 0) {
        url += steamIds[i];
      } else {
        url += ',' + steamIds[i];
      }
    }
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        data => {
          resolve(data as ClanDetailsForPlayersResponse);
        },
        error => {
          reject(new Error(`Could not get clan avatar. Error ${error.message}`));
        },
      );
    });
  }
}
