import { Injectable } from '@angular/core';
import { SETTINGS, CSGOGSI } from 'csgo-gsi-advanced-types';
import ServerSettings = SETTINGS.ServerSettings;
import MatchEvents = SETTINGS.MatchEvents;
import MatchEventsInfo = SETTINGS.MatchEventsInfo;
import matchEvents = SETTINGS.matchEvents;
import GameParameterTypes = SETTINGS.GameParameterTypes;
import EventHandlerType = SETTINGS.EventHandlerType;
import MatchEventTypes = CSGOGSI.constants.MatchEventTypes;
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import {
  matchEventHandlers as defaultMatchEventHandlers,
  matchEventsInfo,
} from './defaultSettings';
import { cloneDeep } from 'lodash';
import * as Joi from '@hapi/joi';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly matchEventHandlersKey = 'matchEventHandlers';

  private readonly emptyServerSettings: ServerSettings = {
    steamWebApiKey: '',
    gameParameters: {},
    authentication: {
      logicalOperator: 'AND',
      acceptedTokens: [],
      acceptedPlayers: [],
    },
    matchEvents: {},
    knownPlayers: [],
    wLed: {
      enabled: false,
    },
  };
  private serverSettings: BehaviorSubject<ServerSettings>;
  private readonly isInitializedSubject: BehaviorSubject<boolean>;

  private localMatchEventHandlers: BehaviorSubject<MatchEvents>;

  constructor(private http: HttpClient) {
    this.serverSettings = new BehaviorSubject<ServerSettings>(this.emptyServerSettings);
    this.localMatchEventHandlers = new BehaviorSubject<MatchEvents>({});
    this.isInitializedSubject = new BehaviorSubject(false);
    this.initializeServerSettings();
  }

  private async initializeServerSettings(): Promise<void> {
    if (this.isInitializedSubject.value === true) {
      return;
    }
    try {
      await this.getServerSettings();
      this.isInitializedSubject.next(true);
    } catch (error) {
      setTimeout(() => {
        this.initializeServerSettings();
      }, 1000);
    }
  }

  public isInitialized(): Observable<boolean> {
    return this.isInitializedSubject;
  }

  public resetGameState(): Observable<void> {
    const url = `${environment.serverSettings.baseUrl}${environment.serverSettings.resetGameState}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, {}, httpOptions).pipe(
      map(() => {}),
      catchError((error) => {
        return throwError('Error on resetting game state');
      }),
    );
  }

  public getServerSettings(): Promise<ServerSettings> {
    const url = `${environment.serverSettings.baseUrl}${environment.serverSettings.get}`;
    return new Promise((resolve, reject) => {
      this.http.get<ServerSettings>(url).subscribe(
        data => {
          this.serverSettings.next(data);
          resolve(cloneDeep(this.serverSettings.value));
        },
        error => {
          reject(new Error(`Could not get server settings. Error ${error.message}`));
        },
      );
    });
  }

  public setServerSettings(settings: ServerSettings): Observable<ServerSettings> {
    const url = `${environment.serverSettings.baseUrl}${environment.serverSettings.get}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<ServerSettings>(url, settings, httpOptions).pipe(
      map((serverSettings: ServerSettings) => {
        this.serverSettings.next(serverSettings);
        return serverSettings;
      }),
      distinctUntilChanged(),
      catchError(() => {
        return throwError('Error on setting server settings.');
      }),
    );
  }

  public getMatchEventsInfo(): MatchEventsInfo {
    return matchEventsInfo;
  }

  public getLocalMatchEventHandlers(): MatchEvents {
    const loadedData = JSON.parse(localStorage.getItem(this.matchEventHandlersKey));
    let matchEventHandlers: MatchEvents;
    try {
      matchEventHandlers = Joi.attempt(loadedData, matchEvents);
    } catch (error) {
      console.log('Loading default match event handlers.');
      matchEventHandlers = cloneDeep(defaultMatchEventHandlers);
    }
    this.localMatchEventHandlers.next(matchEventHandlers);
    return this.localMatchEventHandlers.value;
  }

  public setLocalMatchEventHandlers(matchEventHandlers: MatchEvents): void {
    localStorage.setItem(this.matchEventHandlersKey, JSON.stringify(matchEventHandlers));
  }

  public c4Timer(): Observable<number> {
    return this.serverSettings.pipe(
      map((serverSettings: ServerSettings) => {
        return serverSettings.gameParameters[GameParameterTypes.BOMB_TIMER];
      }),
      distinctUntilChanged(),
    );
  }

  public defuseTimeNormal(): Observable<number> {
    return this.serverSettings.pipe(
      map((serverSettings: ServerSettings) => {
        return serverSettings.gameParameters[GameParameterTypes.NORMAL_DEFUSE_TIME];
      }),
      distinctUntilChanged(),
    );
  }

  public defuseTimeKit(): Observable<number> {
    return this.serverSettings.pipe(
      map((serverSettings: ServerSettings) => {
        return serverSettings.gameParameters[GameParameterTypes.KIT_DEFUSE_TIME];
      }),
      distinctUntilChanged(),
    );
  }

  public enabledSounds(): Observable<MatchEventTypes[]> {
    return this.localMatchEventHandlers.pipe(
      map((handlers: MatchEvents) => {
        const events: MatchEventTypes[] = [];
        for (const event of Object.values(MatchEventTypes)) {
          if (handlers && handlers[event] && handlers[event].includes(EventHandlerType.SOUND)) {
            events.push(event);
          }
        }
        return events;
      }),
      distinctUntilChanged((old, curr) => {
        if (old.length !== curr.length) {
          return false;
        }
        for (let i = 0; i < old.length; ++i) {
          if (old[i] !== curr[i]) {
            return false;
          }
        }
        return true;
      }),
    );
  }
}
