import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { CountdownModule } from 'ngx-countdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetService } from './services/asset.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { SettingsModule } from './settings/settings.module';
import { SettingsService } from './services/settings.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    CountdownModule,
    BrowserAnimationsModule,
    MaterialModule,
    SettingsModule,
  ],
  providers: [AssetService, SettingsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
