import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { TimesPipe } from './pipes/times.pipe';

@NgModule({
  declarations: [ImageFallbackDirective, TimesPipe],
  exports: [ImageFallbackDirective, TimesPipe],
  imports: [CommonModule],
})
export class SharedModule {}
