"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants = require("./constants");
const Joi = require("@hapi/joi");
var _SETTINGS;
(function (_SETTINGS) {
    var MatchEventTypes = constants._CSGOGSI.MatchEventTypes;
    function getValuesFromEnum(enu) {
        return Object.keys(enu).map(key => enu[key]);
    }
    let EventHandlerType;
    (function (EventHandlerType) {
        EventHandlerType[EventHandlerType["LED"] = 0] = "LED";
        EventHandlerType[EventHandlerType["SOUND"] = 1] = "SOUND";
    })(EventHandlerType = _SETTINGS.EventHandlerType || (_SETTINGS.EventHandlerType = {}));
    _SETTINGS.eventHandlerType = Joi.number().valid(...getValuesFromEnum(EventHandlerType));
    _SETTINGS.matchEvents = Joi.object()
        .pattern(new RegExp("^" + getValuesFromEnum(MatchEventTypes).join("$|^") + "$"), Joi.array().items(_SETTINGS.eventHandlerType))
        .unknown(false);
    _SETTINGS.player = Joi.object({
        steamid: Joi.string().required(),
        displayName: Joi.string().required()
    }).unknown(false);
    _SETTINGS.authentication = Joi.object({
        logicalOperator: Joi.string()
            .valid("OR", "AND")
            .required(),
        acceptedTokens: Joi.array()
            .items(Joi.string().allow(""))
            .required(),
        acceptedPlayers: Joi.array()
            .items(_SETTINGS.player)
            .required(),
        referencePlayer: _SETTINGS.player
    }).unknown(false);
    let GameParameterTypes;
    (function (GameParameterTypes) {
        GameParameterTypes["BOMB_TIMER"] = "Bomb Timer";
        GameParameterTypes["NORMAL_DEFUSE_TIME"] = "Normal Defuse Time";
        GameParameterTypes["KIT_DEFUSE_TIME"] = "Kit Defuse Time";
        GameParameterTypes["BUY_TIME"] = "Buy Time";
        GameParameterTypes["FREEZE_TIME"] = "Freeze Time";
        GameParameterTypes["OWN_TEAM_SIZE"] = "Own Team Size";
        GameParameterTypes["ENEMY_TEAM_SIZE"] = "Enemy Team Size";
        GameParameterTypes["COST_ARMOR"] = "Cost Kevlar";
        GameParameterTypes["KILL_STREAK_TIME_THRESHOLD"] = "Kill Streak Time Threshold";
    })(GameParameterTypes = _SETTINGS.GameParameterTypes || (_SETTINGS.GameParameterTypes = {}));
    _SETTINGS.gameParameterTypes = Joi.number().valid(...getValuesFromEnum(GameParameterTypes));
    _SETTINGS.gameParameters = Joi.object()
        .pattern(new RegExp("^" + getValuesFromEnum(GameParameterTypes).join("$|^") + "$"), Joi.number()
        .positive()
        .allow(0))
        .unknown(false);
    _SETTINGS.wLedIpAddress = Joi.string().ip();
    _SETTINGS.wLedPort = Joi.number().port();
    _SETTINGS.wLed = Joi.object({
        enabled: Joi.boolean().required(),
        ipAddress: _SETTINGS.wLedIpAddress.allow(''),
        port: _SETTINGS.wLedPort
    }).unknown(false);
    _SETTINGS.wLedEnabled = _SETTINGS.wLed.append({
        ipAddress: _SETTINGS.wLedIpAddress.required(),
        port: _SETTINGS.wLedPort.required()
    }).unknown(false);
    _SETTINGS.serverSettingsNoMeta = Joi.object({
        steamWebApiKey: Joi.string()
            .allow("")
            .required(),
        authentication: _SETTINGS.authentication.required(),
        matchEvents: _SETTINGS.matchEvents.required(),
        gameParameters: _SETTINGS.gameParameters.required(),
        knownPlayers: Joi.array().items(_SETTINGS.player),
        wLed: _SETTINGS.wLed,
    }).unknown(false);
    _SETTINGS.serverSettings = _SETTINGS.serverSettingsNoMeta
        .append({
        meta: Joi.object()
    })
        .unknown(false);
})(_SETTINGS = exports._SETTINGS || (exports._SETTINGS = {}));
