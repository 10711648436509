<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav
    #sidenav
    (mouseleave)="onLeaveSidenav()"
    (openedStart)="onOpenedStart()"
    (closedStart)="onClosedStart()"
  >
    <app-settings [fetchDataTrigger]="fetchSettings" [closeSettings]="closeSettings"></app-settings>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <div class="sidenav-trigger" (mouseenter)="onEnterTrigger()"></div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="progress-spinner-container" *ngIf="!(settingsService.isInitialized() | async)">
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="200">
    </mat-progress-spinner>
  </div>
</div>
