"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _CSGOGSI;
(function (_CSGOGSI) {
    _CSGOGSI.CSGO_APP_ID = 730;
    let WeaponStates;
    (function (WeaponStates) {
        WeaponStates["HOLSTERED"] = "holstered";
        WeaponStates["ACTIVE"] = "active";
        WeaponStates["RELOADING"] = "reloading";
    })(WeaponStates = _CSGOGSI.WeaponStates || (_CSGOGSI.WeaponStates = {}));
    let WeaponTypes;
    (function (WeaponTypes) {
        WeaponTypes["PISTOL"] = "Pistol";
        WeaponTypes["SHOTGUN"] = "Shotgun";
        WeaponTypes["MACHINE_GUN"] = "Machine Gun";
        WeaponTypes["SUBMACHINE_GUN"] = "Submachine Gun";
        WeaponTypes["RIFLE"] = "Rifle";
        WeaponTypes["SNIPER_RIFLE"] = "SniperRifle";
        WeaponTypes["GRENADE"] = "Grenade";
        WeaponTypes["KNIFE"] = "Knife";
        WeaponTypes["BOMB"] = "C4";
        WeaponTypes["FISTS"] = "Fists";
        WeaponTypes["MELEE"] = "Melee";
        WeaponTypes["STACKABLE"] = "StackableItem";
        WeaponTypes["TABLET"] = "Tablet";
        WeaponTypes["BREACH_CHARGE"] = "Breach Charge";
    })(WeaponTypes = _CSGOGSI.WeaponTypes || (_CSGOGSI.WeaponTypes = {}));
    let WeaponNames;
    (function (WeaponNames) {
        // Knifes
        WeaponNames["KNIFE_T"] = "weapon_knife_t";
        WeaponNames["KNIFE_DEFAULT_CT"] = "weapon_knife_default_ct";
        WeaponNames["KNIFE_CT"] = "weapon_knife";
        WeaponNames["KNIFE_BAYONET"] = "weapon_bayonet";
        WeaponNames["KNIFE_CSS"] = "weapon_knife_css";
        WeaponNames["KNIFE_FLIP"] = "weapon_knife_flip";
        WeaponNames["KNIFE_GUT"] = "weapon_knife_gut";
        WeaponNames["KNIFE_KARAMBIT"] = "weapon_knife_karambit";
        WeaponNames["KNIFE_M9_BAYONET"] = "weapon_knife_m9_bayonet";
        WeaponNames["KNIFE_TACTIAL"] = "weapon_knife_tactical";
        WeaponNames["KNIFE_FALCHION"] = "weapon_knife_falchion";
        WeaponNames["KNIFE_SURVIVAL_BOWIE"] = "weapon_knife_survival_bowie";
        WeaponNames["KNIFE_BUTTERLY"] = "weapon_knife_butterfly";
        WeaponNames["KNIFE_PUSH"] = "weapon_knife_push";
        WeaponNames["KNIFE_CORD"] = "weapon_knife_cord";
        WeaponNames["KNIFE_CANIS"] = "weapon_knife_canis";
        WeaponNames["KNIFE_URSUS"] = "weapon_knife_ursus";
        WeaponNames["KNIFE_GYPSY_JACKKNIFE"] = "weapon_knife_gypsy_jackknife";
        WeaponNames["KNIFE_OUTDOOR"] = "weapon_knife_outdoor";
        WeaponNames["KNIFE_STILETTO"] = "weapon_knife_stiletto";
        WeaponNames["KNIFE_WIDOWMAKER"] = "weapon_knife_widowmaker";
        WeaponNames["KNIFE_SKELETON"] = "weapon_knife_skeleton";
        // Pistols
        WeaponNames["DEAGLE"] = "weapon_deagle";
        WeaponNames["BERETTAS"] = "weapon_elite";
        WeaponNames["FIVESEVEN"] = "weapon_fiveseven";
        WeaponNames["GLOCK"] = "weapon_glock";
        WeaponNames["CZ"] = "weapon_cz75a";
        WeaponNames["P2000"] = "weapon_hkp2000";
        WeaponNames["P250"] = "weapon_p250";
        WeaponNames["REVOLVO"] = "weapon_revolver";
        WeaponNames["TEC9"] = "weapon_tec9";
        WeaponNames["USPS"] = "weapon_usp_silencer";
        // Shotguns
        WeaponNames["XM"] = "weapon_xm1014";
        WeaponNames["NOVA"] = "weapon_nova";
        WeaponNames["MAG7"] = "weapon_mag7";
        WeaponNames["SAWEDOFF"] = "weapon_sawedoff";
        // Machine Guns
        WeaponNames["M249"] = "weapon_m249";
        WeaponNames["NEGEV"] = "weapon_negev";
        // Submachine Guns
        WeaponNames["MAC10"] = "weapon_mac10";
        WeaponNames["BIZON"] = "weapon_bizon";
        WeaponNames["MP5S"] = "weapon_mp5sd";
        WeaponNames["MP7"] = "weapon_mp7";
        WeaponNames["MP9"] = "weapon_mp9";
        WeaponNames["P90"] = "weapon_p90";
        WeaponNames["UMP"] = "weapon_ump45";
        // Rifles
        WeaponNames["AK47"] = "weapon_ak47";
        WeaponNames["AUG"] = "weapon_aug";
        WeaponNames["FAMAS"] = "weapon_famas";
        WeaponNames["GALIL"] = "weapon_galilar";
        WeaponNames["M4A1"] = "weapon_m4a1";
        WeaponNames["M4A1S"] = "weapon_m4a1_silencer";
        WeaponNames["SIG"] = "weapon_sg556";
        // Sniper Rifles
        WeaponNames["AWP"] = "weapon_awp";
        WeaponNames["AUTO_T"] = "weapon_g3sg1";
        WeaponNames["AUTO_CT"] = "weapon_scar20";
        WeaponNames["STEYR"] = "weapon_ssg08";
        // Grenades
        WeaponNames["SMOKE"] = "weapon_smokegrenade";
        WeaponNames["DECOY"] = "weapon_decoy";
        WeaponNames["DIVERSION"] = "weapon_diversion";
        WeaponNames["HE"] = "weapon_hegrenade";
        WeaponNames["FRAG"] = "weapon_frag_grenade";
        WeaponNames["FLASH"] = "weapon_flashbang";
        WeaponNames["INCENDIARY"] = "weapon_incgrenade";
        WeaponNames["MOLOTOV"] = "weapon_molotov";
        WeaponNames["FIREBOMB"] = "weapon_firebomb";
        WeaponNames["TA"] = "weapon_tagrenade";
        WeaponNames["SNOWBALL"] = "weapon_snowball";
        WeaponNames["BREACH"] = "weapon_breachcharge";
        // Bomb
        WeaponNames["BOMB"] = "weapon_c4";
        // Other
        WeaponNames["TASER"] = "weapon_taser";
        WeaponNames["HEALTH"] = "weapon_healthshot";
        WeaponNames["FISTS"] = "weapon_fists";
        WeaponNames["HAMMER"] = "weapon_hammer";
        WeaponNames["AXE"] = "weapon_axe";
        WeaponNames["WRENCH"] = "weapon_spanner";
        WeaponNames["TABLET"] = "weapon_tablet";
        WeaponNames["SHIELD"] = "weapon_shield";
    })(WeaponNames = _CSGOGSI.WeaponNames || (_CSGOGSI.WeaponNames = {}));
    let GrenadeTypes;
    (function (GrenadeTypes) {
        GrenadeTypes["SMOKE"] = "smoke";
        GrenadeTypes["DECOY"] = "decoy";
        GrenadeTypes["FRAG"] = "frag";
        GrenadeTypes["FIRE"] = "firebomb";
        GrenadeTypes["FLASH"] = "flashbang";
        GrenadeTypes["INFERNO"] = "inferno";
    })(GrenadeTypes = _CSGOGSI.GrenadeTypes || (_CSGOGSI.GrenadeTypes = {}));
    let BombStates;
    (function (BombStates) {
        BombStates["PLANTED"] = "planted";
        BombStates["PLANTING"] = "planting";
        BombStates["EXPLODED"] = "exploded";
        BombStates["DEFUSING"] = "defusing";
        BombStates["DEFUSED"] = "defused";
        BombStates["CARRIED"] = "carried";
        BombStates["DROPPED"] = "dropped";
    })(BombStates = _CSGOGSI.BombStates || (_CSGOGSI.BombStates = {}));
    let BombStatesRound;
    (function (BombStatesRound) {
        BombStatesRound["PLANTED"] = "planted";
        BombStatesRound["DEFUSED"] = "defused";
        BombStatesRound["EXPLODED"] = "exploded";
    })(BombStatesRound = _CSGOGSI.BombStatesRound || (_CSGOGSI.BombStatesRound = {}));
    let WeaponSlotNames;
    (function (WeaponSlotNames) {
        WeaponSlotNames["WEAPON_0"] = "weapon_0";
        WeaponSlotNames["WEAPON_1"] = "weapon_1";
        WeaponSlotNames["WEAPON_2"] = "weapon_2";
        WeaponSlotNames["WEAPON_3"] = "weapon_3";
        WeaponSlotNames["WEAPON_4"] = "weapon_4";
        WeaponSlotNames["WEAPON_5"] = "weapon_5";
        WeaponSlotNames["WEAPON_6"] = "weapon_6";
        WeaponSlotNames["WEAPON_7"] = "weapon_7";
        WeaponSlotNames["WEAPON_8"] = "weapon_8";
        WeaponSlotNames["WEAPON_9"] = "weapon_9";
    })(WeaponSlotNames = _CSGOGSI.WeaponSlotNames || (_CSGOGSI.WeaponSlotNames = {}));
    let GameModes;
    (function (GameModes) {
        GameModes["CASUAL"] = "casual";
        GameModes["COMPETITIVE"] = "competitive";
    })(GameModes = _CSGOGSI.GameModes || (_CSGOGSI.GameModes = {}));
    let MapPhases;
    (function (MapPhases) {
        MapPhases["LIVE"] = "live";
        MapPhases["INTERMISSION"] = "intermission";
        MapPhases["GAMEOVER"] = "gameover";
        MapPhases["WARMUP"] = "warmup";
    })(MapPhases = _CSGOGSI.MapPhases || (_CSGOGSI.MapPhases = {}));
    let RoundPhases;
    (function (RoundPhases) {
        RoundPhases["LIVE"] = "live";
        RoundPhases["FREEZETIME"] = "freezetime";
        RoundPhases["OVER"] = "over";
    })(RoundPhases = _CSGOGSI.RoundPhases || (_CSGOGSI.RoundPhases = {}));
    let RoundWinningType;
    (function (RoundWinningType) {
        RoundWinningType["CT_WIN_TIME"] = "ct_win_time";
        RoundWinningType["CT_WIN_ELIMINATION"] = "ct_win_elimination";
        RoundWinningType["CT_WIN_DEFUSE"] = "ct_win_defuse";
        RoundWinningType["T_WIN_ELIMINATION"] = "t_win_elimination";
        RoundWinningType["T_WIN_BOMB"] = "t_win_bomb";
        RoundWinningType["NOTHING"] = "";
    })(RoundWinningType = _CSGOGSI.RoundWinningType || (_CSGOGSI.RoundWinningType = {}));
    let CountdownPhases;
    (function (CountdownPhases) {
        CountdownPhases["FREEZETIME"] = "freezetime";
        CountdownPhases["BOMB"] = "bomb";
        CountdownPhases["WARMUP"] = "warmup";
        CountdownPhases["LIVE"] = "live";
        CountdownPhases["OVER"] = "over";
        CountdownPhases["DEFUSE"] = "defuse";
        CountdownPhases["PAUSED"] = "paused";
        CountdownPhases["TIMEOUT_CT"] = "timeout_ct";
        CountdownPhases["TIMEOUT_T"] = "timeout_t";
    })(CountdownPhases = _CSGOGSI.CountdownPhases || (_CSGOGSI.CountdownPhases = {}));
    let PlayerActivityStates;
    (function (PlayerActivityStates) {
        PlayerActivityStates["PLAYING"] = "playing";
        PlayerActivityStates["FREE"] = "free";
        PlayerActivityStates["TEXTINPUT"] = "textinput";
        PlayerActivityStates["MENU"] = "menu";
    })(PlayerActivityStates = _CSGOGSI.PlayerActivityStates || (_CSGOGSI.PlayerActivityStates = {}));
    let ObservatorSlots;
    (function (ObservatorSlots) {
        ObservatorSlots[ObservatorSlots["ONE"] = 1] = "ONE";
        ObservatorSlots[ObservatorSlots["TWO"] = 2] = "TWO";
        ObservatorSlots[ObservatorSlots["THREE"] = 3] = "THREE";
        ObservatorSlots[ObservatorSlots["FOUR"] = 4] = "FOUR";
        ObservatorSlots[ObservatorSlots["FIVE"] = 5] = "FIVE";
        ObservatorSlots[ObservatorSlots["SIX"] = 6] = "SIX";
        ObservatorSlots[ObservatorSlots["SEVEN"] = 7] = "SEVEN";
        ObservatorSlots[ObservatorSlots["EIGHT"] = 8] = "EIGHT";
        ObservatorSlots[ObservatorSlots["NINE"] = 9] = "NINE";
        ObservatorSlots[ObservatorSlots["TEN"] = 0] = "TEN";
    })(ObservatorSlots = _CSGOGSI.ObservatorSlots || (_CSGOGSI.ObservatorSlots = {}));
    let TeamNames;
    (function (TeamNames) {
        TeamNames["CT"] = "CT";
        TeamNames["T"] = "T";
    })(TeamNames = _CSGOGSI.TeamNames || (_CSGOGSI.TeamNames = {}));
    let TeamNamesPretty;
    (function (TeamNamesPretty) {
        TeamNamesPretty["CT"] = "Counter-Terrorists";
        TeamNamesPretty["T"] = "Terrorists";
    })(TeamNamesPretty = _CSGOGSI.TeamNamesPretty || (_CSGOGSI.TeamNamesPretty = {}));
    let MatchEventTypes;
    (function (MatchEventTypes) {
        MatchEventTypes["BOMB_DROPPED"] = "Bomb Dropped";
        MatchEventTypes["BOMB_PICKED"] = "Bomb Picked";
        MatchEventTypes["BOMB_PLANTED"] = "Bomb Planted";
        MatchEventTypes["BOMB_EXPLODED"] = "Bomb Exploded";
        MatchEventTypes["BOMB_DEFUSED"] = "Bomb Defused";
        MatchEventTypes["FIRST_BLOOD"] = "First Blood";
        MatchEventTypes["DOUBLE_KILL"] = "Double Kill";
        MatchEventTypes["TRIPLE_KILL"] = "Triple Kill";
        MatchEventTypes["ULTRA_KILL"] = "Ultra Kill";
        MatchEventTypes["RAMPAGE"] = "Rampage";
        MatchEventTypes["KILLING_SPREE"] = "Killing Spree";
        MatchEventTypes["DOMINATING"] = "Dominating";
        MatchEventTypes["MEGA_KILL"] = "Mega Kill";
        MatchEventTypes["UNSTOPPABLE"] = "Unstoppable";
        MatchEventTypes["WICKED_SICK"] = "Wicked Sick";
        MatchEventTypes["MONSTER_KILL"] = "Monster Kill";
        MatchEventTypes["GODLIKE"] = "Godlike";
        MatchEventTypes["BEYOND_GODLIKE"] = "Beyond Godlike";
        MatchEventTypes["TEAM_KILL"] = "Team Kill";
        MatchEventTypes["ACE"] = "Ace";
        MatchEventTypes["ROUND_NEW"] = "Round New";
        MatchEventTypes["ROUND_WON"] = "Round Won";
        MatchEventTypes["ROUND_LOST"] = "Round Lost";
        MatchEventTypes["ROUND_LIVE"] = "Round Live";
        MatchEventTypes["ROUND_OVER"] = "Round Over";
        MatchEventTypes["ROUND_FLAWLESS"] = "Flawless Round Win";
        MatchEventTypes["ROUND_HUMILIATION"] = "Humiliating Round Loss";
        MatchEventTypes["MAP_LIVE"] = "Map Live";
        MatchEventTypes["MATCH_NEW"] = "Match New";
        MatchEventTypes["MATCH_WON"] = "Match Won";
        MatchEventTypes["MATCH_LOST"] = "Match Lost";
        MatchEventTypes["MATCH_TIE"] = "Match Tie";
        MatchEventTypes["GAME_OVER"] = "Game Over";
        MatchEventTypes["CLUTCH_START"] = "Clutch Start";
        MatchEventTypes["BE_WATER"] = "Be Water";
        MatchEventTypes["CLUTCH_4"] = "Clutch \u2013 4 Left";
        MatchEventTypes["CLUTCH_3"] = "Clutch \u2013 3 Left";
        MatchEventTypes["CLUTCH_2"] = "Clutch \u2013 2 Left";
        MatchEventTypes["CLUTCH_1"] = "Clutch \u2013 1 Left";
        MatchEventTypes["CLUTCH_WON_SIMPLE"] = "Clutch Won \u2013 Simple";
        MatchEventTypes["CLUTCH_WON_DIFFICULT"] = "Clutch Won \u2013 Difficult";
        MatchEventTypes["CLUTCH_LOST_SIMPLE"] = "Clutch Lost \u2013 Simple";
        MatchEventTypes["CLUTCH_LOST_DIFFICULT"] = "Clutch Lost \u2013 Difficult";
        MatchEventTypes["ONE_ENEMY_LEFT"] = "One Enemy Left";
        MatchEventTypes["LAST_MAN_STANDING"] = "Last Man Standing";
        MatchEventTypes["PUMPGUN"] = "Pumpgun";
        MatchEventTypes["GLASS_CANNON"] = "Glass Cannon";
        MatchEventTypes["JAMES_BOND"] = "James Bond";
        MatchEventTypes["HALT_STOP"] = "Halt Stop!";
        MatchEventTypes["UNTERBRECHEN"] = "Unterbrechen";
        MatchEventTypes["JETZT_GEHTS_LOS"] = "Jetzt geht's los!";
    })(MatchEventTypes = _CSGOGSI.MatchEventTypes || (_CSGOGSI.MatchEventTypes = {}));
    let SocketEventTypes;
    (function (SocketEventTypes) {
        SocketEventTypes["GAME_DATA"] = "game-data";
    })(SocketEventTypes = _CSGOGSI.SocketEventTypes || (_CSGOGSI.SocketEventTypes = {}));
    _CSGOGSI.MapNames = {
        ar_baggage: 'Baggage',
        ar_dizzy: 'Dizzy',
        ar_lunacy: 'Lunacy',
        ar_monastery: 'Monastery',
        ar_shoots: 'Shoots',
        coop_kasbah: 'Virus Outbreak',
        cs_agency: 'Agency',
        cs_assault: 'Assault',
        cs_insertion: 'Insertion',
        cs_italy: 'Italy',
        cs_militia: 'Militia',
        cs_office: 'Office',
        cs_workout: 'Workout',
        de_abbey: 'Abbey',
        de_austria: 'Austria',
        de_aztec: 'Aztec',
        de_bank: 'Bank',
        de_biome: 'Biome',
        de_breach: 'Breach',
        de_cache: 'Cache',
        de_canals: 'Canals',
        de_cbble: 'Cobblestone',
        de_dust2: 'Dust II',
        de_dust: 'Dust',
        de_inferno: 'Inferno',
        de_lake: 'Lake',
        de_mirage: 'Mirage',
        de_nuke: 'Nuke',
        de_overpass: 'Overpass',
        de_ruby: 'Ruby',
        de_safehouse: 'Safehouse',
        de_seaside: 'Seaside',
        de_shipped: 'Shipped',
        de_shortdust: 'Shortdust',
        de_shortnuke: 'Shortnuke',
        de_shorttrain: 'Shorttrain',
        de_stmarc: 'St. Marc',
        de_studio: 'Studio',
        de_subzero: 'SUB-ZERO',
        de_sugarcane: 'Sugarcane',
        de_train: 'Train',
        de_vertigo: 'Vertigo',
        de_zoo: 'Zoo',
        dz_blacksite: 'Blacksite',
        dz_junglety: 'Junglety',
        dz_sirocco: 'Sirocco',
        gd_cbble: 'Cobblestone',
        gd_rialto: 'Rialto',
    };
    let ClutchSituations;
    (function (ClutchSituations) {
        ClutchSituations[ClutchSituations["NONE"] = 0] = "NONE";
        ClutchSituations[ClutchSituations["SIMPLE"] = 1] = "SIMPLE";
        ClutchSituations[ClutchSituations["DIFFICULT"] = 2] = "DIFFICULT";
    })(ClutchSituations = _CSGOGSI.ClutchSituations || (_CSGOGSI.ClutchSituations = {}));
})(_CSGOGSI = exports._CSGOGSI || (exports._CSGOGSI = {}));
