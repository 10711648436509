export default class Utils {
  private static readonly SECONDS_PER_YEAR = 31536000;
  private static readonly SECONDS_PER_DAY = 86400;
  private static readonly SECONDS_PER_HOUR = 3600;
  private static readonly SECONDS_PER_MINUTE = 60;

  // Source: https://stackoverflow.com/a/31406310
  static secondsToHhMmSs(seconds: number, printLeadingZeroes = true): string {
    const hh = Utils.getHoursFromSeconds(seconds);
    const mm = Utils.getMinutesFromSeconds(seconds);
    const ss = Utils.getSecondsFromSeconds(seconds);

    let asText = '';

    if (hh !== 0 || printLeadingZeroes) {
      asText += hh < 10 ? '0' + hh : hh;
    }
    if (asText.length > 0 || mm !== 0 || printLeadingZeroes) {
      if (asText.length > 0) {
        asText += ':';
      }
      asText += mm < 10 ? '0' + mm : mm;
    }
    if (asText.length > 0 || ss !== 0 || printLeadingZeroes) {
      if (asText.length > 0) {
        asText += ':';
      }
      asText += ss < 10 ? '0' + ss : ss;
    }
    return asText;
  }

  private static getHoursFromSeconds(seconds: number): number {
    return Math.floor(
      ((seconds % Utils.SECONDS_PER_YEAR) % Utils.SECONDS_PER_DAY) / Utils.SECONDS_PER_HOUR,
    );
  }

  private static getMinutesFromSeconds(seconds: number): number {
    return Math.floor(
      (((seconds % Utils.SECONDS_PER_YEAR) % Utils.SECONDS_PER_DAY) % Utils.SECONDS_PER_HOUR) /
        Utils.SECONDS_PER_MINUTE,
    );
  }

  private static getSecondsFromSeconds(seconds: number): number {
    return (
      (((seconds % Utils.SECONDS_PER_YEAR) % Utils.SECONDS_PER_DAY) % Utils.SECONDS_PER_HOUR) %
      Utils.SECONDS_PER_MINUTE
    );
  }

  static holdsValue<T>(data: T): data is NonNullable<T> {
    return data !== undefined && data !== null;
  }

  static getNumberValuesFromEnum(enu: any) {
    return Object.keys(enu)
      .map(key => enu[key])
      .filter(val => typeof val === 'number');
  }

  static getStringValuesFromEnum(enu: any) {
    return Object.keys(enu)
      .map(key => enu[key])
      .filter(val => typeof val === 'string');
  }
}

export class Queue<T> extends Array<T> {
  public enqueue(val: T): void {
    this.push(val);
  }

  public dequeue(): T | undefined {
    return this.shift();
  }

  public peek(): T {
    return this[0];
  }

  public isEmpty(): boolean {
    return this.length === 0;
  }
}
