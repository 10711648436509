import { CSGOGSI, SETTINGS } from 'csgo-gsi-advanced-types';
import MatchEvents = SETTINGS.MatchEvents;
import MatchEventsInfo = SETTINGS.MatchEventsInfo;
import EventHandlerType = SETTINGS.EventHandlerType;
import MatchEventTypes = CSGOGSI.constants.MatchEventTypes;

export const matchEventsInfo: MatchEventsInfo = {
  [MatchEventTypes.BOMB_DROPPED]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BOMB_PICKED]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BOMB_PLANTED]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BOMB_EXPLODED]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BOMB_DEFUSED]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.FIRST_BLOOD]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.DOUBLE_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.TRIPLE_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ULTRA_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.RAMPAGE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.KILLING_SPREE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.DOMINATING]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MEGA_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.UNSTOPPABLE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.WICKED_SICK]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MONSTER_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.GODLIKE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BEYOND_GODLIKE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.TEAM_KILL]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ACE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_NEW]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_WON]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_LOST]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_LIVE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_OVER]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_FLAWLESS]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ROUND_HUMILIATION]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MAP_LIVE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MATCH_NEW]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MATCH_WON]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MATCH_LOST]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.MATCH_TIE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_START]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_4]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_3]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_2]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_1]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_WON_SIMPLE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_WON_DIFFICULT]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_LOST_SIMPLE]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.CLUTCH_LOST_DIFFICULT]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.ONE_ENEMY_LEFT]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.LAST_MAN_STANDING]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.PUMPGUN]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.GLASS_CANNON]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.JAMES_BOND]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.BE_WATER]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.HALT_STOP]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.UNTERBRECHEN]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
  [MatchEventTypes.JETZT_GEHTS_LOS]: {
    implementedHandlers: [EventHandlerType.SOUND],
  },
};

export const matchEventHandlers: MatchEvents = {
  [MatchEventTypes.BOMB_DROPPED]: [EventHandlerType.SOUND],
  [MatchEventTypes.BOMB_PICKED]: [EventHandlerType.SOUND],
  [MatchEventTypes.BOMB_PLANTED]: [EventHandlerType.SOUND],
  [MatchEventTypes.BOMB_EXPLODED]: [EventHandlerType.SOUND],
  [MatchEventTypes.BOMB_DEFUSED]: [EventHandlerType.SOUND],
  [MatchEventTypes.FIRST_BLOOD]: [EventHandlerType.SOUND],
  [MatchEventTypes.DOUBLE_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.TRIPLE_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.ULTRA_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.RAMPAGE]: [EventHandlerType.SOUND],
  [MatchEventTypes.KILLING_SPREE]: [EventHandlerType.SOUND],
  [MatchEventTypes.DOMINATING]: [EventHandlerType.SOUND],
  [MatchEventTypes.MEGA_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.UNSTOPPABLE]: [EventHandlerType.SOUND],
  [MatchEventTypes.WICKED_SICK]: [EventHandlerType.SOUND],
  [MatchEventTypes.MONSTER_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.GODLIKE]: [EventHandlerType.SOUND],
  [MatchEventTypes.BEYOND_GODLIKE]: [EventHandlerType.SOUND],
  [MatchEventTypes.TEAM_KILL]: [EventHandlerType.SOUND],
  [MatchEventTypes.ACE]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_NEW]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_WON]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_LOST]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_LIVE]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_OVER]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_FLAWLESS]: [EventHandlerType.SOUND],
  [MatchEventTypes.ROUND_HUMILIATION]: [EventHandlerType.SOUND],
  [MatchEventTypes.MAP_LIVE]: [EventHandlerType.SOUND],
  [MatchEventTypes.MATCH_NEW]: [EventHandlerType.SOUND],
  [MatchEventTypes.MATCH_WON]: [EventHandlerType.SOUND],
  [MatchEventTypes.MATCH_LOST]: [EventHandlerType.SOUND],
  [MatchEventTypes.MATCH_TIE]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_START]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_4]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_3]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_2]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_1]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_WON_SIMPLE]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_WON_DIFFICULT]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_LOST_SIMPLE]: [EventHandlerType.SOUND],
  [MatchEventTypes.CLUTCH_LOST_DIFFICULT]: [EventHandlerType.SOUND],
  [MatchEventTypes.ONE_ENEMY_LEFT]: [EventHandlerType.SOUND],
  [MatchEventTypes.LAST_MAN_STANDING]: [EventHandlerType.SOUND],
  [MatchEventTypes.PUMPGUN]: [EventHandlerType.SOUND],
  [MatchEventTypes.GLASS_CANNON]: [EventHandlerType.SOUND],
  [MatchEventTypes.JAMES_BOND]: [EventHandlerType.SOUND],
  [MatchEventTypes.BE_WATER]: [EventHandlerType.SOUND],
  [MatchEventTypes.HALT_STOP]: [EventHandlerType.SOUND],
  [MatchEventTypes.UNTERBRECHEN]: [EventHandlerType.SOUND],
  [MatchEventTypes.JETZT_GEHTS_LOS]: [EventHandlerType.SOUND],
};
