<form #settingsForm name="settings-form" [formGroup]="settingsFormGroup" spellcheck="false">
  <div class="actions">
    <button
      mat-raised-button
      color="primary"
      [disabled]="fetchingData || disableReload"
      (click)="reloadSettings()"
    >
      <i class="material-icons">refresh</i>
    </button>
    <button
      mat-raised-button
      color="primary"
      class="submit"
      [disabled]="!settingsFormGroup.dirty || !settingsFormGroup.valid || fetchingData"
      (click)="submitSettings()"
    >
      Save
    </button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="!settingsFormGroup.dirty || fetchingData"
      (click)="discardChanges()"
    >
      Discard
    </button>
  </div>
  <ngx-simplebar class="inputs">
    <h2>Steam Web API</h2>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Key</mat-label>
        <input formControlName="steamWebApiKey" matInput placeholder="Your Steam Web API Key" />
      </mat-form-field>
    </div>
    <h2>Authentication</h2>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Tokens</mat-label>
        <input
          formControlName="acceptedTokens"
          matInput
          placeholder="Comma separated list of accepted tokens"
        />
      </mat-form-field>
    </div>
    <div class="row center padding-bottom">
      <mat-radio-group
        class="authentication-logical-operator"
        name="authentication-logical-operator"
        required="true"
        formControlName="authenticationLogicalOperator"
      >
        <mat-radio-button value="OR">
          OR
        </mat-radio-button>
        <mat-radio-button value="AND">
          AND
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Accepted Players</mat-label>
        <mat-select
          multiple
          placeholder="Select players"
          formControlName="acceptedPlayers"
          [matTooltip]="selectedPlayersToString()"
          [matTooltipClass]="'tooltip-accepted-players'"
          [compareWith]="comparePlayers"
          matTooltipPosition="below"
        >
          <ng-container
            *ngIf="
              currentServerSettings &&
              currentServerSettings.authentication &&
              currentServerSettings.knownPlayers !== null
            "
          >
            <mat-option
              *ngFor="let player of currentServerSettings.knownPlayers"
              [value]="player"
              >{{ player.displayName }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Reference Player</mat-label>
        <mat-select
          placeholder="Select a reference player"
          formControlName="referencePlayer"
          [matTooltip]="
            settingsFormGroup.get('referencePlayer').value
              ? settingsFormGroup.get('referencePlayer').value.displayName
              : ''
          "
          [compareWith]="comparePlayers"
          matTooltipPosition="below"
        >
          <ng-container
            *ngIf="
              currentServerSettings &&
              currentServerSettings.authentication &&
              currentServerSettings.knownPlayers !== null
            "
          >
            <mat-option
              *ngFor="let player of currentServerSettings.knownPlayers"
              [value]="player"
              >{{ player.displayName }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <h2>Events</h2>
    <div class="row header">
      <div class="cell name"></div>
      <div class="cell sound">Sound</div>
      <div class="cell led">LED</div>
    </div>
    <ng-container
      *ngIf="currentServerSettings && currentServerSettings.matchEvents && matchEventsInfoSorted"
    >
      <div
        class="row event"
        *ngFor="let event of matchEventsInfoSorted; trackBy: trackEventsBy"
        [matTooltip]="event.value.description"
        matTooltipShowDelay="750"
      >
        <div class="cell name">{{ event.name }}</div>
        <div class="cell sound">
          <mat-checkbox [formControlName]="'matchEvent' + event.name + 'Sound'"></mat-checkbox>
        </div>
        <div class="cell led">
          <mat-checkbox [formControlName]="'matchEvent' + event.name + 'Led'"></mat-checkbox>
        </div>
      </div>
    </ng-container>
    <h2>Game Parameters</h2>
    <ng-container
      *ngIf="
        currentServerSettings && currentServerSettings.gameParameters && gameParametersInfoSorted
      "
    >
      <div
        class="row"
        *ngFor="let parameter of gameParametersInfoSorted; trackBy: trackGameParametersBy"
      >
        <mat-form-field class="full-width">
          <mat-label>{{ parameter.name }}</mat-label>
          <input
            [formControlName]="'gameParameter' + parameter.name"
            matInput
            type="number"
            [placeholder]="parameter.value.description"
          />
        </mat-form-field>
      </div>
    </ng-container>
    <div class="split-heading">
      <div class="left"><h2>WLED</h2></div>
      <div class="right">
        <mat-slide-toggle
          formControlName="wLedEnabled"
          (change)="onWLedToggle($event)"
        ></mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>IP Address</mat-label>
        <input
          matInput
          formControlName="wLedIpAddress"
          placeholder="192.168.13.37"
          [errorStateMatcher]="wLedInputsErrorStateMatcher"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="full-width">
        <mat-label>Port</mat-label>
        <input
          formControlName="wLedPort"
          matInput
          type="number"
          placeholder="1337"
          [errorStateMatcher]="wLedInputsErrorStateMatcher"
        />
      </mat-form-field>
    </div>
    <h2>Danger Zone</h2>
    <button
      class="reset"
      mat-raised-button
      color="warn"
      [disabled]="fetchingData"
      (click)="resetGameState()"
    >
      Reset Game Data
    </button>
  </ngx-simplebar>
</form>

<div class="progress-spinner-container" *ngIf="fetchingData">
  <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="100">
  </mat-progress-spinner>
</div>
