"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const schemata_1 = require("./schemata");
const constants_1 = require("./constants");
const settings_1 = require("./settings");
var CSGOGSI;
(function (CSGOGSI) {
    let types;
    (function (types) {
    })(types = CSGOGSI.types || (CSGOGSI.types = {}));
    let schemata;
    (function (schemata) {
        schemata.provider = schemata_1._CSGOGSI.provider;
        schemata.auth = schemata_1._CSGOGSI.auth;
        schemata.grenadeOnMap = schemata_1._CSGOGSI.grenadeOnMap;
        schemata.grenadesOnMap = schemata_1._CSGOGSI.grenadesOnMap;
        schemata.bomb = schemata_1._CSGOGSI.bomb;
        schemata.weapon = schemata_1._CSGOGSI.weapon;
        schemata.weaponSlots = schemata_1._CSGOGSI.weaponSlots;
        schemata.team = schemata_1._CSGOGSI.team;
        schemata.map = schemata_1._CSGOGSI.map;
        schemata.phaseCountdowns = schemata_1._CSGOGSI.phaseCountdowns;
        schemata.round = schemata_1._CSGOGSI.round;
        schemata.playerState = schemata_1._CSGOGSI.playerState;
        schemata.player = schemata_1._CSGOGSI.player;
        schemata.playerStats = schemata_1._CSGOGSI.playerStats;
        schemata.playerList = schemata_1._CSGOGSI.playerList;
        schemata.allPlayers = schemata_1._CSGOGSI.allPlayers;
        // export import added  = _schemata.added ;
        // export import previously  = _schemata.previously ;
        schemata.gameState = schemata_1._CSGOGSI.gameState;
    })(schemata = CSGOGSI.schemata || (CSGOGSI.schemata = {}));
    let constants;
    (function (constants) {
        constants.CSGO_APP_ID = constants_1._CSGOGSI.CSGO_APP_ID;
        constants.WeaponStates = constants_1._CSGOGSI.WeaponStates;
        constants.WeaponNames = constants_1._CSGOGSI.WeaponNames;
        constants.WeaponTypes = constants_1._CSGOGSI.WeaponTypes;
        constants.GrenadeTypes = constants_1._CSGOGSI.GrenadeTypes;
        constants.BombStates = constants_1._CSGOGSI.BombStates;
        constants.BombStatesRound = constants_1._CSGOGSI.BombStatesRound;
        constants.WeaponSlotNames = constants_1._CSGOGSI.WeaponSlotNames;
        constants.GameModes = constants_1._CSGOGSI.GameModes;
        constants.MapPhases = constants_1._CSGOGSI.MapPhases;
        constants.RoundPhases = constants_1._CSGOGSI.RoundPhases;
        constants.RoundWinningType = constants_1._CSGOGSI.RoundWinningType;
        constants.CountdownPhases = constants_1._CSGOGSI.CountdownPhases;
        constants.PlayerActivityStates = constants_1._CSGOGSI.PlayerActivityStates;
        constants.ObservatorSlots = constants_1._CSGOGSI.ObservatorSlots;
        constants.TeamNames = constants_1._CSGOGSI.TeamNames;
        constants.TeamNamesPretty = constants_1._CSGOGSI.TeamNamesPretty;
        constants.MatchEventTypes = constants_1._CSGOGSI.MatchEventTypes;
        constants.SocketEventTypes = constants_1._CSGOGSI.SocketEventTypes;
        constants.MapNames = constants_1._CSGOGSI.MapNames;
        constants.ClutchSituations = constants_1._CSGOGSI.ClutchSituations;
    })(constants = CSGOGSI.constants || (CSGOGSI.constants = {}));
})(CSGOGSI = exports.CSGOGSI || (exports.CSGOGSI = {}));
var STEAM_API;
(function (STEAM_API) {
})(STEAM_API = exports.STEAM_API || (exports.STEAM_API = {}));
var SETTINGS;
(function (SETTINGS) {
    SETTINGS.EventHandlerType = settings_1._SETTINGS.EventHandlerType;
    SETTINGS.eventHandlerType = settings_1._SETTINGS.eventHandlerType;
    SETTINGS.matchEvents = settings_1._SETTINGS.matchEvents;
    SETTINGS.player = settings_1._SETTINGS.player;
    SETTINGS.authentication = settings_1._SETTINGS.authentication;
    SETTINGS.GameParameterTypes = settings_1._SETTINGS.GameParameterTypes;
    SETTINGS.gameParameterTypes = settings_1._SETTINGS.gameParameterTypes;
    SETTINGS.gameParameters = settings_1._SETTINGS.gameParameters;
    SETTINGS.serverSettings = settings_1._SETTINGS.serverSettings;
    SETTINGS.serverSettingsNoMeta = settings_1._SETTINGS.serverSettingsNoMeta;
    SETTINGS.wLedIpAddress = settings_1._SETTINGS.wLedIpAddress;
    SETTINGS.wLedPort = settings_1._SETTINGS.wLedPort;
    SETTINGS.wLed = settings_1._SETTINGS.wLed;
    SETTINGS.wLedEnabled = settings_1._SETTINGS.wLedEnabled;
})(SETTINGS = exports.SETTINGS || (exports.SETTINGS = {}));
