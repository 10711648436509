import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscriber } from 'rxjs';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'csgo-game-monitor-client';
  @ViewChild('sidenav') sidenav: MatSidenav;

  public fetchSettings: Observable<void>;
  public closeSettings: Observable<void>;
  private openSidenavSubscribers: Subscriber<void>[];
  private closeSidenavSubscribers: Subscriber<void>[];

  constructor(public settingsService: SettingsService) {
    this.openSidenavSubscribers = [];
    this.fetchSettings = new Observable(subscriber => {
      this.openSidenavSubscribers.push(subscriber);
    });
    this.closeSidenavSubscribers = [];
    this.closeSettings = new Observable(subscriber => {
      this.closeSidenavSubscribers.push(subscriber);
    });
  }

  private closeSidenavId: number;

  public onEnterTrigger() {
    this.sidenav.open();
  }

  public onLeaveSidenav() {
    this.closeSidenavId = setTimeout(() => {
      // this.sidenav.close();
    }, 750);
  }
  public onOpenedStart() {
    for (const subscriber of this.openSidenavSubscribers) {
      subscriber.next();
    }
  }

  public onClosedStart() {
    for (const subscriber of this.closeSidenavSubscribers) {
      subscriber.next();
    }
  }
}
