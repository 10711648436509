"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Joi = require("@hapi/joi");
const constants = require("./constants");
var _CSGOGSI;
(function (_CSGOGSI) {
    function getValuesFromEnum(enu) {
        return Object.keys(enu).map(key => enu[key]);
    }
    _CSGOGSI.provider = Joi.object({
        name: Joi.string().required(),
        appid: Joi.number()
            .positive()
            .integer()
            .required(),
        version: Joi.number().required(),
        steamid: Joi.string().required(),
        timestamp: Joi.number()
            .positive()
            .required()
        /*timestamp: Joi.date()
          .timestamp("unix")
          .required()
          */
    }).unknown(false);
    _CSGOGSI.auth = Joi.object({
        token: Joi.string()
    }).unknown(false);
    _CSGOGSI.weapon = Joi.object({
        type: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.WeaponTypes)),
        name: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.WeaponNames))
            .required(),
        paintkit: Joi.string().required(),
        state: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.WeaponStates))
            .required(),
        ammo_clip: Joi.number()
            .positive()
            .allow(0),
        ammo_clip_max: Joi.number()
            .positive()
            .allow(0),
        ammo_reserve: Joi.number()
            .positive()
            .allow(0)
    }).unknown(false);
    _CSGOGSI.grenadeOnMap = Joi.object({
        owner: Joi.number()
            .positive()
            .allow(0)
            .required(),
        type: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.GrenadeTypes))
            .required(),
        position: Joi.string(),
        velocity: Joi.string(),
        lifetime: Joi.string().required(),
        effecttime: Joi.string(),
        flames: Joi.object()
    }).unknown(false);
    _CSGOGSI.grenadesOnMap = Joi.object().pattern(Joi.string, _CSGOGSI.grenadeOnMap);
    _CSGOGSI.bomb = Joi.object({
        state: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.BombStates))
            .required(),
        position: Joi.string().pattern(/^-?\d+.\d+\s+-?\d+.\d+\s+-?\d+.\d+$/, "coordinates"),
        player: Joi.number()
            .positive()
            .allow(0),
        countdown: Joi.string().pattern(/^\d+.\d+$/, "bomb-countdown")
    }).unknown(false);
    _CSGOGSI.weaponSlots = Joi.object()
        .pattern(new RegExp('^' + getValuesFromEnum(constants._CSGOGSI.WeaponSlotNames).join("$|^") + "$"), _CSGOGSI.weapon)
        .max(10);
    _CSGOGSI.team = Joi.object({
        name: Joi.string().allow(""),
        score: Joi.number()
            .positive()
            .allow(0),
        consecutive_round_losses: Joi.number()
            .positive()
            .allow(0)
            .required(),
        timeouts_remaining: Joi.number()
            .positive()
            .allow(0)
            .required(),
        matches_won_this_series: Joi.number()
            .positive()
            .allow(0)
            .required()
    }).unknown(false);
    _CSGOGSI.map = Joi.object({
        mode: Joi.string().required(),
        name: Joi.string().required(),
        phase: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.MapPhases))
            .required(),
        round: Joi.number()
            .positive()
            .allow(0)
            .required(),
        team_ct: _CSGOGSI.team.required(),
        team_t: _CSGOGSI.team.required(),
        num_matches_to_win_series: Joi.number()
            .positive()
            .allow(0)
            .required(),
        current_spectators: Joi.number()
            .positive()
            .allow(0)
            .required(),
        souvenirs_total: Joi.number()
            .positive()
            .allow(0)
            .required(),
        round_wins: Joi.object().pattern(/^\d+$/, Joi.string().valid(...getValuesFromEnum(constants._CSGOGSI.RoundWinningType)))
    }).unknown(false);
    _CSGOGSI.phaseCountdowns = Joi.object({
        phase: Joi.string().valid(...getValuesFromEnum(constants._CSGOGSI.PlayerActivityStates)),
        phase_ends_in: Joi.string().pattern(/^\d+.\d+$/, "phase-countdown")
    }).unknown(false);
    _CSGOGSI.teamNames = Joi.string().valid(...getValuesFromEnum(constants._CSGOGSI.TeamNames));
    _CSGOGSI.round = Joi.object({
        phase: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.RoundPhases))
            .required(),
        bomb: Joi.string().valid(...getValuesFromEnum(constants._CSGOGSI.BombStatesRound)),
        win_team: _CSGOGSI.teamNames
    }).unknown(false);
    _CSGOGSI.playerState = Joi.object({
        health: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        armor: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        helmet: Joi.boolean().required(),
        flashed: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        smoked: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        burning: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        money: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        round_kills: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        round_killhs: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        round_totaldmg: Joi.number()
            .integer()
            .positive()
            .allow(0),
        // .required(),
        equip_value: Joi.number()
            .integer()
            .positive()
            .allow(0)
            .required(),
        defusekit: Joi.boolean()
    }).unknown(false);
    _CSGOGSI.playerStats = Joi.object({
        kills: Joi.number().required(),
        assists: Joi.number()
            .positive()
            .allow(0)
            .required(),
        deaths: Joi.number()
            .positive()
            .allow(0)
            .required(),
        mvps: Joi.number()
            .positive()
            .allow(0)
            .required(),
        score: Joi.number()
            .positive()
            .allow(0)
            .required()
    }).unknown(false);
    _CSGOGSI.observerSlots = Joi.string().valid(...getValuesFromEnum(constants._CSGOGSI.ObservatorSlots));
    _CSGOGSI.player = Joi.object({
        steamid: Joi.string().required(),
        name: Joi.string().required(),
        activity: Joi.string()
            .valid(...getValuesFromEnum(constants._CSGOGSI.PlayerActivityStates))
            .required(),
        match_stats: _CSGOGSI.playerStats,
        clan: Joi.string(),
        observer_slot: _CSGOGSI.observerSlots,
        team: _CSGOGSI.teamNames,
        state: _CSGOGSI.playerState,
        weapons: _CSGOGSI.weaponSlots,
        position: Joi.string(),
        forward: Joi.string(),
        spectarget: Joi.string()
    }).unknown(false);
    _CSGOGSI.playerList = Joi.object({
        clan: Joi.string().allow(""),
        name: Joi.string().required(),
        observer_slot: _CSGOGSI.observerSlots.required(),
        team: _CSGOGSI.teamNames,
        state: _CSGOGSI.playerState.required(),
        match_stats: _CSGOGSI.playerStats.required(),
        weapons: _CSGOGSI.weaponSlots.required(),
        position: Joi.string().required(),
        forward: Joi.string().required()
    }).unknown(false);
    _CSGOGSI.allPlayers = Joi.object().pattern(Joi.string().required, _CSGOGSI.playerList);
    /*
    export const added  = Joi.object({
      round: Joi.object({
        bomb: Joi.boolean(),
        win_team: Joi.boolean()
      }),
      map: map
    }).unknown(false);
  
     */
    /*
    export const previously  = Joi.object({
      provider: provider ,
      map: map ,
      round: round ,
      player: player ,
      allplayers: allPlayers ,
      phase_countdowns: phaseCountdowns ,
      grenades: grenades ,
      bomb: bombState
    }).unknown(false);
  
     */
    _CSGOGSI.gameState = Joi.object({
        provider: _CSGOGSI.provider,
        auth: _CSGOGSI.auth,
        player: _CSGOGSI.player,
        allplayers: _CSGOGSI.allPlayers,
        round: _CSGOGSI.round,
        phase_countdowns: _CSGOGSI.phaseCountdowns,
        grenades: _CSGOGSI.grenadesOnMap,
        previously: Joi.object(),
        added: Joi.object(),
        bomb: _CSGOGSI.bomb,
        map: _CSGOGSI.map
    }).unknown(false);
})(_CSGOGSI = exports._CSGOGSI || (exports._CSGOGSI = {}));
