import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { SETTINGS, CSGOGSI } from 'csgo-gsi-advanced-types';
import ServerSettings = SETTINGS.ServerSettings;
import Player = SETTINGS.Player;
import GameParameters = SETTINGS.GameParameters;
import GameParameterTypes = SETTINGS.GameParameterTypes;
import GameParameterInfo = SETTINGS.GameParameterInfo;
import EventHandlerType = SETTINGS.EventHandlerType;
import MatchEvents = SETTINGS.MatchEvents;
import MatchEventInfo = SETTINGS.MatchEventInfo;
import MatchEventsInfo = SETTINGS.MatchEventsInfo;
import WLed = SETTINGS.WLed;
import MatchEventTypes = CSGOGSI.constants.MatchEventTypes;
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import Utils from '../../utils';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as Joi from '@hapi/joi';
import { ErrorStateMatcher } from '@angular/material/core';

interface MatchEventInfoContainer {
  name: string;
  value: MatchEventInfo;
}

interface GameParameterInfoContainer {
  name: string;
  value: GameParameterInfo;
}

class WLedInputsErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form?.touched && control?.invalid;
  }
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Input() fetchDataTrigger: Observable<void>;
  @Input() closeSettings: Observable<void>;

  private retryFetchServerSettings: number;

  public settingsFormGroup: FormGroup;

  public disableReload: boolean;
  public fetchingData: boolean;

  public currentServerSettings: ServerSettings;

  public localMatchEventsInfo: MatchEventsInfo;
  public localMatchEventHandlers: MatchEvents;

  public matchEventsInfoSorted: MatchEventInfoContainer[];
  public gameParametersInfoSorted: GameParameterInfoContainer[];

  public wLedInputsErrorStateMatcher: WLedInputsErrorStateMatcher;

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.disableReload = false;

    this.localMatchEventsInfo = this.settingsService.getMatchEventsInfo();
    this.wLedInputsErrorStateMatcher = new WLedInputsErrorStateMatcher();

    this.fetchData();
  }

  ngOnInit() {
    const authenticationValidator = (formControl: FormControl) => {
      if (!formControl.parent) {
        return { noForm: 'Form has not been created yet.' };
      }

      if (!this.currentServerSettings) {
        return { noSettings: 'No settings received yet.' };
      }

      const tokensControl = formControl.parent.get('acceptedTokens');
      const playersControl = formControl.parent.get('acceptedPlayers');

      const acceptedTokens = tokensControl.value;
      const acceptedPlayers = playersControl.value;

      let error = null;

      const numberKnownPlayers = this.currentServerSettings.knownPlayers
        ? this.currentServerSettings.knownPlayers.length
        : 0;

      if (
        numberKnownPlayers > 0 &&
        (!acceptedTokens || acceptedTokens.length === 0) &&
        (!acceptedPlayers || acceptedPlayers.length === 0)
      ) {
        error = { authentication: 'acceptedTokens or acceptedPlayers has to be defined' };
        tokensControl.setErrors(error);
        playersControl.setErrors(error);
      } else {
        tokensControl.setErrors(null);
        playersControl.setErrors(null);
      }

      return error;
    };

    const referencePlayerValidator = (formControl: FormControl) => {
      if (!this.currentServerSettings) {
        return { noSettings: 'No settings received yet.' };
      }
      const numberKnownPlayers = this.currentServerSettings.knownPlayers
        ? this.currentServerSettings.knownPlayers.length
        : 0;
      if (numberKnownPlayers === 0) {
        return null;
      }
      if (!formControl.value || formControl.value.length === 0) {
        return { required: true };
      }
      return null;
    };

    const wLedIpAddressValidator = (formControl: FormControl) => {
      const wLedEnabled = this.settingsFormGroup?.get('wLedEnabled')?.value;
      if (!wLedEnabled) {
        return null;
      }

      const wLedIpAddressControl = this.settingsFormGroup?.get('wLedIpAddress');

      const wLedIpAddress = wLedIpAddressControl?.value;

      try {
        Joi.attempt(wLedIpAddress, SETTINGS.wLedIpAddress.required());
      } catch (error) {
        return { invalidWLedIpAddress: true };
      }

      return null;
    };

    const wLedPortValidator = (formControl: FormControl) => {
      const wLedEnabled = this.settingsFormGroup?.get('wLedEnabled')?.value;
      if (!wLedEnabled) {
        return null;
      }

      const wLedPortControl = this.settingsFormGroup?.get('wLedPort');
      const wLedPort = wLedPortControl?.value;

      try {
        Joi.attempt(wLedPort, SETTINGS.wLedPort.required());
      } catch (error) {
        return { invalidWLedPort: true };
      }

      return null;
    };

    this.settingsFormGroup = this.formBuilder.group({
      steamWebApiKey: new FormControl('', Validators.required),
      authenticationLogicalOperator: new FormControl('', Validators.required),
      acceptedTokens: new FormControl('', authenticationValidator),
      acceptedPlayers: new FormControl('', authenticationValidator),
      referencePlayer: new FormControl('', [referencePlayerValidator]),
      wLedEnabled: new FormControl('', Validators.required),
      wLedIpAddress: new FormControl('', [wLedIpAddressValidator]),
      wLedPort: new FormControl('', [wLedPortValidator]),
    });

    for (const paramType of Object.values(GameParameterTypes)) {
      const control = new FormControl('', [Validators.required, Validators.min(0)]);
      this.settingsFormGroup.addControl('gameParameter' + paramType, control);
    }

    for (const eventType of Object.values(MatchEventTypes)) {
      this.settingsFormGroup.addControl('matchEvent' + eventType + 'Sound', new FormControl(''));
      this.settingsFormGroup.addControl('matchEvent' + eventType + 'Led', new FormControl(''));
    }

    this.fetchDataTrigger.subscribe(() => {
      this.onFetchData();
    });

    this.closeSettings.subscribe(() => {
      this.onClose();
    });
  }

  public onWLedToggle(event: MatSlideToggleChange): void {
    this.setWLedInputsEnabled(event.checked);
  }

  private setWLedInputsEnabled(enabled: boolean): void {
    if (enabled) {
      this.settingsFormGroup?.get('wLedIpAddress')?.enable();
      this.settingsFormGroup?.get('wLedPort')?.enable();
    } else {
      this.settingsFormGroup?.get('wLedIpAddress')?.disable();
      this.settingsFormGroup?.get('wLedPort')?.disable();
    }
  }

  private refreshSteamWebApiKey(): void {
    this.settingsFormGroup
      .get('steamWebApiKey')
      .setValue(this.currentServerSettings.steamWebApiKey);
  }

  private refreshAcceptedTokens(): void {
    this.settingsFormGroup
      .get('acceptedTokens')
      .setValue(this.currentServerSettings.authentication.acceptedTokens.join(','));
  }

  private refreshAcceptedPlayers(): void {
    this.settingsFormGroup
      .get('acceptedPlayers')
      .setValue(this.currentServerSettings.authentication.acceptedPlayers);
  }

  private refreshAuthenticationLogicalOperator(): void {
    this.settingsFormGroup
      .get('authenticationLogicalOperator')
      .setValue(this.currentServerSettings.authentication.logicalOperator);
  }

  private refreshReferencePlayer(): void {
    this.settingsFormGroup
      .get('referencePlayer')
      .setValue(this.currentServerSettings.authentication.referencePlayer);
  }

  private refreshGameParameters(): void {
    if (!this.currentServerSettings.gameParameters) {
      return;
    }
    for (const paramType of Object.values(GameParameterTypes)) {
      const param: number = this.currentServerSettings.gameParameters[paramType];
      if (!Utils.holdsValue(param)) {
        continue;
      }
      this.settingsFormGroup.get('gameParameter' + paramType).setValue(param);
    }
  }

  private refreshMatchEvents(): void {
    if (
      !this.currentServerSettings.matchEvents ||
      !this.currentServerSettings.meta ||
      !this.currentServerSettings.meta.matchEventsInfo ||
      !this.localMatchEventsInfo ||
      !this.localMatchEventHandlers
    ) {
      return;
    }
    for (const eventName of Object.values(MatchEventTypes)) {
      // LED
      const eventInfoServer: MatchEventInfo = this.currentServerSettings.meta.matchEventsInfo[
        eventName
      ];
      if (!eventInfoServer) {
        continue;
      }
      const disabledLed = !eventInfoServer.implementedHandlers.includes(EventHandlerType.LED);
      const handlerTypesServer: EventHandlerType[] = this.currentServerSettings.matchEvents[
        eventName
      ];
      const checkedLed =
        Utils.holdsValue(handlerTypesServer) && handlerTypesServer.includes(EventHandlerType.LED);
      const formGroupLed = this.settingsFormGroup.get('matchEvent' + eventName + 'Led');
      formGroupLed.setValue(checkedLed);
      if (disabledLed) {
        formGroupLed.disable();
      }

      // Sound
      const eventInfoLocal: MatchEventInfo = this.localMatchEventsInfo[eventName];
      const disabledSound = !eventInfoLocal.implementedHandlers.includes(EventHandlerType.SOUND);
      const handlerTypesLocal: EventHandlerType[] = this.localMatchEventHandlers[eventName];
      const checkedSound =
        Utils.holdsValue(handlerTypesLocal) && handlerTypesLocal.includes(EventHandlerType.SOUND);
      const formGroupSound = this.settingsFormGroup.get('matchEvent' + eventName + 'Sound');
      formGroupSound.setValue(checkedSound);
      if (disabledSound) {
        formGroupSound.disable();
      }
    }
  }

  private refreshWled(): void {
    if (!this.currentServerSettings?.wLed) {
      return;
    }
    let control = this.settingsFormGroup.get('wLedEnabled');
    if (control) {
      control.setValue(this.currentServerSettings.wLed.enabled);
    }
    control = this.settingsFormGroup.get('wLedIpAddress');
    if (control) {
      control.setValue(this.currentServerSettings.wLed.ipAddress);
    }
    control = this.settingsFormGroup.get('wLedPort');
    if (control) {
      control.setValue(this.currentServerSettings.wLed.port);
    }

    this.setWLedInputsEnabled(this.currentServerSettings.wLed.enabled);
  }

  private refreshForm(): void {
    this.refreshSteamWebApiKey();
    this.refreshAcceptedTokens();
    this.refreshAcceptedPlayers();
    this.refreshAuthenticationLogicalOperator();
    this.refreshReferencePlayer();
    this.refreshGameParameters();
    this.refreshMatchEvents();
    this.refreshWled();

    this.sortMatchEvents();
    this.sortGameParameters();
  }

  private async fetchData(): Promise<void> {
    clearTimeout(this.retryFetchServerSettings);
    this.disableReload = true;
    this.fetchingData = true;
    try {
      this.localMatchEventHandlers = this.settingsService.getLocalMatchEventHandlers();
      this.currentServerSettings = await this.settingsService.getServerSettings();
      this.refreshForm();
      this.fetchingData = false;
      if (this.disableReload) {
        setTimeout(() => {
          this.disableReload = false;
        }, 5000);
      }
    } catch (error) {
      this.retryFetchServerSettings = setTimeout(() => {
        this.fetchData();
      }, 500);
    }
  }

  private onFetchData(): void {
    if (!this.settingsFormGroup.dirty) {
      this.fetchData();
    }
  }

  private onClose(): void {
    this.snackBar.dismiss();
  }

  public selectedPlayersToString(): string {
    const selected = this.settingsFormGroup.get('acceptedPlayers').value;
    if (!selected) {
      return '';
    }
    return selected
      .map((player: Player) => {
        return player.displayName;
      })
      .join(',\n');
  }

  public comparePlayers(a: Player, b: Player): boolean {
    return (!a && !b) || (a && b && a.steamid === b.steamid);
  }

  public trackEventsBy(index: number, eventEntry: MatchEventInfoContainer) {
    return eventEntry.name;
  }

  public sortMatchEvents(): void {
    this.matchEventsInfoSorted = [];
    if (this.currentServerSettings.meta.matchEventsInfo) {
      for (const entry of Object.entries(this.currentServerSettings.meta.matchEventsInfo)) {
        this.matchEventsInfoSorted.push({ name: entry[0], value: entry[1] });
      }
    }
    this.matchEventsInfoSorted.sort((a, b) => {
      return a.value.order - b.value.order;
    });
  }

  public trackGameParametersBy(index: number, eventEntry: GameParameterInfoContainer) {
    return eventEntry.name;
  }

  public sortGameParameters(): void {
    this.gameParametersInfoSorted = [];
    if (this.currentServerSettings.meta.gameParametersInfo) {
      for (const entry of Object.entries(this.currentServerSettings.meta.gameParametersInfo)) {
        this.gameParametersInfoSorted.push({ name: entry[0], value: entry[1] });
      }
    }
    this.gameParametersInfoSorted.sort((a, b) => {
      return a.value.order - b.value.order;
    });
  }

  public reloadSettings(): void {
    this.disableReload = true;
    this.fetchData();
    this.openSnackBar('Reloaded settings!', 'left');
  }

  public resetGameState(): void {
    this.fetchingData = true;
    this.settingsService.resetGameState().subscribe(
      () => this.onResetGameStateSuccess(),
      error => this.onResetGameStateError(),
    );
  }

  private onResetGameStateSuccess(): void {
    this.openSnackBar('Successfully reset game state.', 'left');
    this.fetchingData = false;
  }

  private onResetGameStateError(): void {
    this.openSnackBar('Failed to reset game state!', 'left');
    this.fetchingData = false;
  }

  public submitSettings(): void {
    if (this.settingsFormGroup.valid && this.settingsFormGroup.dirty) {
      this.fetchingData = true;
      const gameParameters: GameParameters = {};
      for (const paramType of Object.values(GameParameterTypes)) {
        gameParameters[paramType] = this.settingsFormGroup.get('gameParameter' + paramType).value;
      }

      const matchEvents: MatchEvents = {};
      for (const eventName of Object.values(MatchEventTypes)) {
        const checkedLed = this.settingsFormGroup.get('matchEvent' + eventName + 'Led').value;
        if (checkedLed) {
          matchEvents[eventName] = [EventHandlerType.LED];
        }
      }

      const matchEventsLocal: MatchEvents = {};
      for (const eventName of Object.values(MatchEventTypes)) {
        const checkedSound = this.settingsFormGroup.get('matchEvent' + eventName + 'Sound').value;
        if (checkedSound) {
          matchEventsLocal[eventName] = [EventHandlerType.SOUND];
        }
      }
      this.settingsService.setLocalMatchEventHandlers(matchEventsLocal);

      const wLed: WLed = {
        enabled: this.settingsFormGroup.get('wLedEnabled').value,
      };

      const wLedIpAddress = this.settingsFormGroup.get('wLedIpAddress').value;
      if (Utils.holdsValue(wLedIpAddress)) {
        wLed.ipAddress = wLedIpAddress;
      }

      const wLedPort = this.settingsFormGroup.get('wLedPort').value;
      if (Utils.holdsValue(wLedPort)) {
        wLed.port = wLedPort;
      }

      this.settingsService
        .setServerSettings({
          steamWebApiKey: this.settingsFormGroup.get('steamWebApiKey').value,
          authentication: {
            logicalOperator: this.settingsFormGroup.get('authenticationLogicalOperator').value,
            acceptedTokens: this.settingsFormGroup.get('acceptedTokens').value.split(','),
            acceptedPlayers: this.settingsFormGroup.get('acceptedPlayers').value,
            referencePlayer: this.settingsFormGroup.get('referencePlayer').value,
          },
          matchEvents,
          gameParameters,
          wLed,
        })
        .subscribe(
          (appliedSettings: ServerSettings) => this.onSubmitResponse(appliedSettings),
          error => this.onSubmitError(),
        );
    }
  }

  private onSubmitResponse(appliedSettings: ServerSettings): void {
    this.currentServerSettings = appliedSettings;
    this.localMatchEventHandlers = this.settingsService.getLocalMatchEventHandlers();
    this.resetForm();
    this.refreshForm();
    this.openSnackBar('Saved settings!', 'left');
    this.fetchingData = false;
  }

  private onSubmitError(): void {
    this.openSnackBar('Failed to save settings!', 'left');
    this.fetchingData = false;
  }

  private resetForm(): void {
    this.settingsFormGroup.reset({
      steamWebApiKey: this.currentServerSettings.steamWebApiKey,
      authenticationLogicalOperator: this.currentServerSettings.authentication.logicalOperator,
      acceptedTokens: this.currentServerSettings.authentication.acceptedTokens,
      matchEvents: this.currentServerSettings.matchEvents,
      wLedEnabled: this.currentServerSettings.wLed.enabled,
      wLedIpAddress: this.currentServerSettings.wLed.ipAddress,
      wLedPort: this.currentServerSettings.wLed.port,
    });
  }

  public discardChanges(): void {
    this.resetForm();
    this.refreshForm();
    this.openSnackBar('Discarded changes!', 'left');
  }

  private openSnackBar(
    message: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    action: string = 'Dismiss',
    duration: number = 5000,
  ) {
    this.snackBar.open(message, action, {
      duration,
      horizontalPosition,
    });
  }
}
